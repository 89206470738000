<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：销售订单</strong>
                                    <el-checkbox v-if="isMobile" style="padding-left: 25px" v-model="showFrom">显示表单</el-checkbox>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row v-show="showFrom">
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="下单日期:">
                                    <el-date-picker
                                            v-model="formData.bill_date_dd"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            :clearable="false"
                                            style="width: 130px"
                                            :readonly="true">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="销售订单-单号:" label-width="106px">
                                    <el-input v-model="formData.bill_id_dd" :readonly="true" placeholder="自动生成"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-form-item label="开单通知书-单号:" label-width="130px">
                                    <el-input ref="bill_id_kdtzs" v-model="formData.bill_id_kdtzs_list"
                                              :readonly="true" placeholder="可生成"
                                              @keyup.native.enter="$_focusNext('bill_id_kd')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-form-item label="销售开单-单号:" label-width="130px">
                                    <el-input ref="bill_id_kd" v-model="formData.bill_id_kd_list"
                                              :readonly="true" placeholder="可生成"
                                              @keyup.native.enter="$_focusNext('settlement_days')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-form-item label="发货单-单号:" label-width="100px">
                                    <el-input ref="bill_id_fh" v-model="formData.bill_id_fh_list"
                                              :readonly="true" placeholder="可生成"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" :readonly="true" v-model="formData.registrar"
                                              style="width: 100%"
                                              placeholder="自动生成" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_warehouse_bill" v-model="formData.last_date_warehouse_bill"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="focusNext('last_name_warehouse_bill')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_warehouse_bill" v-model="formData.last_name_warehouse_bill"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="focusNext('customer')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="交货日期:">
                                    <el-date-picker
                                            ref="delivery_date"
                                            v-model="formData.delivery_date"
                                            @blur="focusNext('customer')"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            :clearable="true"
                                            style="width: 130px">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="客户:">
                                    <el-select ref="customer" v-model="formData.customer" style="width: 100%"
                                               @keyup.native.enter="$_blurNext('customer'),focusNext('sales_man')"
                                               default-first-option remote :remote-method="$_searchCustomerList"
                                               placeholder="可搜索查询"
                                               @visible-change="(v) => $refs.AddCompany.visibleChange(v, this.$refs['customer'])"
                                               clearable
                                               @change="isDataChanage = true,customerChangeEvent($event)"
                                               filterable size="mini">
                                        <el-option
                                                v-for="item in $store.getters.getCustomerList"
                                                :key="item.id"
                                                :label="item.customer_name"
                                                :value="item">
                                            <span style="float: left">{{item.customer_name}}</span>
                                            <span v-if="$_getActionKeyListBoolean(['/customers/editVue'])" style="float: right"><i class="el-icon-edit-outline__close el-icon-edit-outline" @click.stop="$_blurNext('customer'),$refs.AddCompany.editRowEvent(item.id)"/></span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <AddCompany ref="AddCompany"></AddCompany>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="业务员:">
                                    <el-select ref="sales_man" v-model="formData.sales_man" clearable placeholder="可搜索查询"
                                               size="mini"
                                               @change="isDataChanage = true"
                                               @focus="$_salesManListFilter"
                                               :filter-method="$_salesManListFilter"
                                               default-first-option
                                               @keyup.native.enter="$_blurNext('sales_man'),focusNext('settlement_days')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getSalesManList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="2">
                                <el-form-item label="结算天数:" label-width="82px">
                                    <vxe-input style="width: 55px" ref="settlement_days"
                                               v-model="formData.settlement_days"
                                               type="number" size="mini"
                                               @change="isDataChanage=true"
                                               @keyup.native.enter="$_focusNext('clearing_form')">
                                    </vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="结算方式:" label-width="92px">
                                    <el-select ref="clearing_form" v-model="formData.clearing_form" placeholder="可搜索查询"
                                               style="width: 160px"
                                               size="mini"
                                               @change="isDataChanage=true"
                                               @keyup.native.enter="$_blurNext('clearing_form'),$_focusNext('tax_tate_warehouse_bill')"
                                               filterable remote :remote-method="$_getClearingFormList">
                                        <el-option
                                                v-for="item in $store.getters.getClearingFormList"
                                                :key="item.pay_name"
                                                :label="item.pay_name"
                                                :value="item.pay_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="税率:" label-width="60px">
                                    <el-select
                                            ref="tax_tate_warehouse_bill"
                                            v-model="formData.tax_tate_warehouse_bill"
                                            @keyup.native.enter="$_blurNext('tax_tate_warehouse_bill'),$_focusNext('car_code')"
                                            allow-create
                                            filterable
                                            size="mini" clearable
                                            default-first-option
                                            placeholder="可输入、可选择">
                                        <el-option
                                                v-for="item in taxTateWarehouseBillList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-form-item label="司机、车号:" label-width="100px">
                                    <el-input ref="car_code" v-model="formData.car_code"
                                              @change="isDataChanage=true"
                                              @keyup.native.enter="$_focusNext('remark_warehouse_bill')"
                                              placeholder="请输入" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-form-item label="开单备注:">
                                    <el-select
                                            ref="remark_warehouse_bill" v-model="formData.remark_warehouse_bill"
                                            style="width: 100%"
                                            size="mini"
                                            multiple
                                            filterable
                                            allow-create
                                            clearable
                                            @change="isDataChanage=true,selectChange()"
                                            @keyup.native.enter="$_blurNext('remark_warehouse_bill'),$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"
                                            @blur.native.capture="handleRemarkWarehouseBillBlur"
                                            placeholder="可以输入、可选多选">
                                        <el-option
                                                v-for="item in remarkWarehouseBillList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计数量:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总理论重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计金额:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_amount" size="mini"></el-input>
                                </el-form-item>
                            </el-col>



                            <el-col :lg="10" style="margin-bottom: 10px;">
                                <vxe-toolbar class="pull-left" style=" height: 42px; margin-left: 12px;" custom export ref="xToolbar2">
                                </vxe-toolbar>
                                <el-button style=" margin-top: 8px; " type="success"
                                           round @click="$router.push('/salesOrdersSingle')" size="mini">新建
                                </el-button>
                                <el-button type="primary" round :disabled="formData.is_cancel" v-if="$_getActionKeyListBoolean(['/transferSlipInform/buildBillByKDTZS']) && this.isBlankVue(formData.bill_id_kdtzs)"
                                           @click="$_openTypeSelf({path: '/transferSlipInform', query: {bill_id_dd : formData.bill_id_dd}})" size="mini">生成《开单通知书》
                                </el-button>
                                <el-button type="primary" round :disabled="formData.is_cancel" v-if="!this.isBlankVue(formData.bill_id_kdtzs)"
                                           @click="$_openTypeSelf({path: '/transferSlipInform', query: {bill_id_kdtzs : formData.bill_id_kdtzs}})" size="mini">查看《开单通知书》
                                </el-button>
                                <el-button type="primary" round :disabled="formData.is_cancel" v-if="$_getActionKeyListBoolean(['/transferSlipInform/buildBillByKD']) && this.isBlankVue(formData.bill_id_kd)"
                                           @click="$_openTypeSelf({path: '/transferSlip', query: {bill_id_dd : formData.bill_id_dd}})" size="mini">生成《销售开单》
                                </el-button>
                                <el-button type="primary" round :disabled="formData.is_cancel" v-if="!this.isBlankVue(formData.bill_id_kd)"
                                           @click="$_openTypeSelf({path: '/transferSlip', query: {bill_id_kd : formData.bill_id_kd}})" size="mini">查看《销售开单》
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-top: 8px;margin-bottom: 8px; ">返回
                                </el-button>
                                <el-button id="createBill" style="margin-top: 8px;margin-left: 12px;" type="danger" plain
                                           :disabled=" this.loading || this.formData.is_cancel"
                                           round @click="onSubmit" size="mini">保存
                                </el-button>
                            </el-col>

                        </el-row>

                    </el-col>

                </el-row>
                <el-row>

                    <el-col :lg="24">
                        <MnGrid ref="xGrid" search-type="salesOrdersSingle" :height="(this.getViewHeight() - 290)+''" id="SalesOrdersSingleId" :table-column="tableColumn" :table-data-main="tableDataMain" :form-data="formData"></MnGrid>
                    </el-col>

                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'

    import MnGrid from "../../components/MnGrid";
    import AddCompany from "../../components/AddCompany";
    export default {
        components: {AddCompany,MnGrid},
        data() {
            return {
                pageType:'销售订单',
                filterData: {
                    customer_name:'',
                    state:"激活"
               },
                isAddBill:true,
                loading:false,
                remarkWarehouseBillList: [
                    {value: '实重', label: '实重'},
                    {value: '等款', label: '等款'},
                    {value: '费用入单', label: '费用入单'},
                    {value: '理计', label: '理计'},
                    {value: '发单', label: '发单'},
                    {value: '已收款', label: '已收款'}
                ],
                tableColumn: [
                    {type: 'seq', title: '序号',className: 'unEditor', width: 40},
                    {
                        field:'specification',
                        width: 150, title: '实际规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'category',
                        width: 50,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{
                        field:'single_count', title: '数量', editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.singleCountEvent}
                       }
                   },*/
                    {
                        field:'single_count', title: '支数',
                        slots: {edit: 'single_count_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'unit',
                        width: 45,
                        title: '单位',
                        slots: {edit: 'unit_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },

                    /*{
                        field:'single_price', title: '每支单价',
                        slots: {edit: 'single_price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },*/
                    {
                        field:'price', width: 60, title: '单价',
                        titleHelp: {message: '单位(吨) 单价= 吨价\n单位(支) 单价= 每支单价'},
                        slots: {edit: 'price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'warehouse_out',visible: true,
                        width: 90,
                        title: '调出仓库',
                        slots: {edit: 'warehouse_out_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },{
                        field:'warehouse_out_id',visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调出仓库Id'
                    },
                    {field:'remark_stock',width: 70, title: '产品备注', editRender: {name: 'input', props: {controls: false}}},
                    {
                        field: 'single_price_practical', width: 70, title: '实收单价',
                        slots: {edit: 'single_price_practical_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field: 'theory_weight',
                        width: 50,
                        title: '理计',
                        slots: {edit: 'theory_weight_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'stock_bill_date_dd',
                        title: '订单日期',
                        className: 'unEditor',
                        width: 170,
                        slots: {edit: 'stock_bill_date_dd_edit'},
                        editRender: {autofocus: 'input.el-input__inner' /*,enabled:true*/},
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd HH:mm:ss')
                        }
                    },
                    {
                        field:'single_price', width: 70, className: 'unEditor', title: '每支单价',
                        titleHelp: {message: '(自动判断 单位:支)每支单价=单价'}
                   },
                    {
                        field:'tons_of_price', width: 60, className: 'unEditor', title: '吨价',
                        titleHelp: {message: '当：单位（支）\n吨价 = 产品金额 ÷ 过磅重\n当：单位（吨）\n吨价 = 单价'}
                   },
                    /*{
                        field:'single_price', title: '每支单价', editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.singleCountEvent}
                       }
                   },*/
                    {
                        field:'actual_thickness', title: '实厚',
                        slots: {edit: 'actual_thickness_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{
                        field:'actual_thickness',
                        title: '实厚',
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   },*/
                    {field:'single_weight',className: 'unEditor', title: '支重'},//, editRender: {name: 'ElInputNumber', props: {controls: false}}
                    {field:'total_count',className: 'unEditor', title: '库存'},
                    {
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },
                    {field:'theoretical_weight_single',className: 'unEditor',width: 90,titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'}, title: '理论支重'},
                    {field:'amount_of_product', className: 'unEditor',width: 90, title: '产品金额', titleHelp: {message: '单位(支) 产品金额= 数量*每支单价'}},


                    /*{
                        field:'remark_stock', title: '产品备注',
                        slots: {edit: 'remark_stock_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },*/
                    {
                        field:'weighing_heavy',className: 'unEditor',
                        titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                        title: '过磅重', visible: false
                   },
                    {field:'batch_number',visible: false, className: 'unEditor',title: '批号'},
                    {field:'warehouse4', className: 'unEditor',title: '厂一'},
                    {field:'warehouse5',className: 'unEditor', title: '厂二'},
                    {field:'warehouse1',className: 'unEditor', title: '仓一'},
                    {field:'warehouse2',className: 'unEditor', title: '仓二'},
                    {field:'warehouse3',className: 'unEditor', title: '仓三'},
                    {field:'warehouse9',className: 'unEditor', title: '仓四'},
                    {field:'warehouse7',className: 'unEditor', title: '新门市'},
                    {
                        field:'dd_state_name', width: 80, title: '订单状态'
                    },
                    {
                        field:'dd_is_ok_count', width: 90, title: '已完成支数'
                    },
                    /*{
                        field: 'stock_bill_date_dd', visible: true,
                        title: '订单日期',
                        width: 170,
                        editRender: {
                            name: 'ElDatePicker',
                            autofocus: 'input.el-input__inner',
                            props: {type: 'datetime', format: 'yyyy-MM-dd HH:mm:ss'}
                        }
                    },*/
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}},

                ],
                tableDataMain: [],
                specification: [],
                categoryList: [],
                unitList: [],
                warehouseList: [],
                formData: {
                    car_code: '',
                    settlement_days: '',
                    clearing_form: '欠款',
                    tax_tate_warehouse_bill: '',
                    bill_date: '',
                    bill_date_kd : '',
                    bill_date_dd : new Date(),
                    delivery_date: '',
                    total_theoretical_weight:'',
                    bill_id_dd: '',
                    bill_id_kdtzs : '',
                    bill_id_kdtzs_list: '',
                    bill_id_kd : '',
                    bill_id_kd_list : '',
                    bill_id_fh : '',
                    bill_id_fh_list : '',
                    bill_id: '',
                    customer: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    total_number: '',
                    is_ticket: '',
                    sales_man : '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '销售订单',
                    bill_id_type: 'DD',
                    is_cancel: false,
               },
                activeName: 'first',
                checked: false,
                regionList: [],
                //（板）（足方）（足板）
                taxTateWarehouseBillList: [
                    {label: '(方)', value: '(方)'},
                    {label: '(板)', value: '(板)'},
                    {label: '(足方)',value: '(足方)'}
                ],
           }
       },
        methods: {
            //客户选择修改，自动设置对应业务员
            customerChangeEvent(item){
                this.formData.customer = item.customer_name;
                this.formData.sales_man = item.sales_man;
           },
            //计算行理论支数
            getTheTheoryOfCount({row}){
                if(row.weighing_heavy  && row.theoretical_weight ){
                    row.the_theory_of_count = this.$XEUtils.round(Number(this.$XEUtils.divide(Number(row.weighing_heavy) , Number(row.theoretical_weight))),1)
                    if (isNaN(row.the_theory_of_count )){
                        row.the_theory_of_count = null;
                   }
               }else{
                    row.the_theory_of_count = null;
               }
           },
            //计算行 理论重量 计算 理论支重
            getTheTheoryOfCountEvent({row}) {
                //console.log(`${column.title} 触发 change 事件`)
                //console.log(`getTheTheoryOfCountEvent 触发 change 事件` + row.specification + "   :  " + row.single_count)
                //初始化
                row.theoretical_weight = Number(0);
                row.theoretical_weight_single_count = Number(0);
                if (row.specification != null && row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    const wallThickness = Number(s[2]);
                    const lengthExtent = Number(s[3].slice(0, s[3].indexOf("米") + 1).replace("米", ""));
                    const theoretical_weight = (((sideA + sideB) * 2 - 4 * wallThickness) * wallThickness * 0.00785 * lengthExtent) / 1000;
                    row.theoretical_weight = this.$XEUtils.round(theoretical_weight,6);
                    if (row.single_count != null) {
                        row.theoretical_weight_single_count = this.$XEUtils.round(this.$XEUtils.multiply(theoretical_weight , Number(row.single_count)),6);
                   }
                    //row.unit == '支' &&
                    if (row.single_count != null) {
                        //过磅重
                        row.weighing_heavy = row.theoretical_weight_single_count
                   }

               }
                //console.log(row)
                //console.log(column)
           },
            //合计整个表格的理论重量
            sumTheoreticalWeight() {
                //const $grid = this.$refs.tableDataMainXGrid
                let theoreticalWeightAll = Number(0);
                for (var t in this.tableDataMain) {
                    //console.log(this.tableDataMain[t].theoretical_weight);
                    if (this.tableDataMain[t].theoretical_weight_single_count != null) {
                        theoreticalWeightAll = this.$XEUtils.add(theoreticalWeightAll,Number(this.tableDataMain[t].theoretical_weight_single_count));
                   }
               }
                this.formData.total_theoretical_weight = this.$XEUtils.round(Number(theoreticalWeightAll),6);
                //console.log(theoreticalWeightAll);
           },
            //计算行产品金额
            getAmountOfProductEvent({row}) {
                //console.log(`getAmountOfProductEvent触发 change 事件`)
                row.amount_of_product = Number(0);
                if (row.unit != null && ((row.tons_of_price != null && row.weighing_heavy != null) || (row.single_price != null && row.single_count != null))) {
                    if (row.unit == '吨' && row.tons_of_price != null && row.weighing_heavy != null) {
                        row.amount_of_product = this.$XEUtils.round(Number(row.tons_of_price * row.weighing_heavy),2);
                   } else if (row.unit == '支' && row.single_price != null && row.single_count != null) {
                        row.amount_of_product = this.$XEUtils.round(Number(row.single_price * row.single_count),2);
                   } else {
                        row.amount_of_product = '';
                   }
               }
           },
            //合计产品金额
            sumAmountOfProduct() {
                var amountOfProductAll = Number(0);
                for (var t in this.tableDataMain) {
                    //console.log("this.tableDataMain[t].amount_of_product");
                    //console.log(this.tableDataMain[t].amount_of_product);
                    if (this.tableDataMain[t].amount_of_product != null) {
                        amountOfProductAll = Number(amountOfProductAll + Number(this.tableDataMain[t].amount_of_product));
                   }
               }
                this.formData.total_amount = this.$XEUtils.round(amountOfProductAll,2);
                //console.log("合计金额：" + this.formData.amount_of_product)
           },
            //计算行 支重
            getSingleWeight({row}) {
                row.single_weight = '';
                if (row.single_count != null && row.weighing_heavy != null) {
                    row.single_weight = Number(Number(row.weighing_heavy * 1000) / this.$XEUtils.round(Number(row.single_count)),2);
               }
           },
            //合计支数
            sumSingleCount() {
                var singleCountAll = Number(0);
                for (var t in this.tableDataMain) {
                    //console.log(this.tableDataMain[t].theoretical_weight);
                    if (this.tableDataMain[t].single_count != null) {
                        singleCountAll = Number(singleCountAll + Number(this.tableDataMain[t].single_count));
                   }
               }
                this.formData.total_number = singleCountAll;
           },
            singleCountEvent({row}) {
                if(row != null){
                    //计算行理论重量
                    this.getTheTheoryOfCountEvent({row});
                    //计算行产品金额
                    this.getAmountOfProductEvent({row});
                    //计算行 支重
                    this.getSingleWeight({row});
                    //合计支数
                    this.sumSingleCount();
                    //合计整个表格的理论重量
                    this.sumTheoreticalWeight();
                    //合计产品金额
                    this.sumAmountOfProduct();
               }
           },
           editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            onSubmit() {
                this.loading = true
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                setTimeout(() => {
                    let url = '';
                    if(this.isAddBill){
                        url = '/admin/salesOrdersSingle/saveVue';
                   }else{
                        url = '/admin/salesOrdersSingle/updateVue';
                   }
                    this.formData.remark_warehouse_bill = this.formData.remark_warehouse_bill.toString();
                    this.formData.only_bill_date = this.formData.bill_date_dd
                    this.$axios({
                        method:'post',
                        url:url,
                        data: {
                            formData : this.formData,
                            tableDataMain : this.$refs.xGrid.$refs.baseGird.getTableData().fullData,
                       },
                   }).then((response) =>{         //这里使用了ES6的语法
                        loading.close();
                        this.loading = false
                        //console.log(response)       //请求成功返回的数据
                        if(response.status === 200 && response.data.state == 'ok'){
                            this.$message({
                                showClose: true,
                                message: '保存成功',
                                type: 'success'
                           });

                            this.formData = response.data.warehouseBill;
                            if(this.isAddBill){
                                this.isAddBill = false;
                                this.$router.push({path: "/salesOrdersSingle", query: {bill_id_dd: this.formData.bill_id_dd}})
                           }else{
                                this.$_searchSalesOrdersSingle();
                           }
                            //this.tableDataMain = this.$_createRowData(response.data.stocks);
                       }else{
                            this.loading = false
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }
                   }).catch((error) =>{
                        this.loading = false
                        console.log(error)
                        loading.close();
                        this.$message({
                            showClose: true,
                            message: '网络超时，请重试！',
                            type: 'error'
                       });
                   });
               }, 300)

           },



            /**
             * 选中规格后执行对应的操作
             * @param row
             * 扩展继续查询该规格历史交易记录、进货记录、历史报价、调仓信息
             */
            autocompleteSelect({row}) {
                // this.getTheTheoryOfCountEvent({row});
                this.singleCountEvent({row});
                //自动设置类别,根据前面两个值来对比判断
                if (row.specification != null && row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    if (sideA == sideB) {
                        row.category = '方管'
                   } else {
                        row.category = '矩管'
                   }
               }
           },


            //查询实际规格
            specificationSelect(val, {row, rowIndex}) {
                row.specification = val.specification_name;
                //自动设置类别,根据前面两个值来对比判断
                if (row.specification != null && row.specification.split("*").length >= 4) {
                    const s = row.specification.split("*")
                    const sideA = Number(s[0]);
                    const sideB = Number(s[1]);
                    if (sideA == sideB) {
                        row.category = '方管'
                   } else {
                        row.category = '矩管'
                   }
               }
                //查询存货
                this.searchWarehouseStockBySpecificationName({row, rowIndex});
                this.singleCountEvent({row});
           },
            searchWarehouseStockBySpecificationName({row, rowIndex}) {
                //先清空行中的所有仓库库存数量
                for (var i in this.tableDataMain[rowIndex]) {
                    //console.log("先清空行中的所有仓库库存数量: ");
                    let name = i.replace("warehouse", "").trim();
                    if(this.isNumberVue(Number(name))){
                        this.tableDataMain[rowIndex][i] = 0;
                   }
               }
                this.$axios({
                    method: 'POST',
                    url: '/admin/productQuotation/searchWarehouseStockBySpecificationName',
                    data: {
                        specification: row.specification,
                        category: row.category
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    //console.log(response.data)       //请求成功返回的数据
                    if (response.status === 200) {
                        //row = {...row, ...response.data}
                        for (var st in response.data) {
                            this.tableDataMain[rowIndex][st] = response.data[st];
                       }
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            selectChange(){
                //console.log('change')
                //console.log(this.$refs['remark_warehouse_bill'])
                let _this = this;
                setTimeout(function() {
                    _this.$refs['remark_warehouse_bill'].blur()
                }, 50);
            },
            handleRemarkWarehouseBillBlur() {
                //console.log('test');
                let query = this.$refs.remark_warehouse_bill.query;
                //console.log(this.$refs.remark_warehouse_bill.query);
                if (null != query && query.length > 0) {
                    //let item = this.formData.remark_warehouse_bill.find(el => el == query);
                    this.formData.remark_warehouse_bill.push(query);
                    this.$_blurNext('remark_warehouse_bill');
                    /*if (item == undefined) {
                        //this.$refs.remark_warehouse_bill.clearSingleSelect();
                        this.formData.remark_warehouse_bill.push(query);
                   } else {
                        this.username = user.key;
                        this.password = user.value;
                   }*/
                }
            },
            //查询仓库库存
            searchWarehouseStock(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/specification/searchByTotalSingleCount',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200) {
                        this.specification = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            focusNext(nextRef) {
                this.$refs[nextRef].focus()
           },
            blurNext(thisRef) {
                this.$refs[thisRef].blur()
           }
       },

        created() {
            this.formData.bill_id_dd = this.$route.query.bill_id_dd;
            /*this.$_searchCustomerList('');
            this.$_searchUnitList('');
            this.$_searcWarehouseList('');
            this.$store.getters.getWarehouseListDefault
            this.$_searchCategoryList('');*/
            this.$_getAccountListByRoleId('10');//业务员
            if (this.formData.bill_id_dd == null || this.formData.bill_id_dd == '') {
                var list = [];
                this.tableDataMain = this.$_createRowData(list);
           } else {
                this.$_searchSalesOrdersSingle();
           }
            //如果记录员(业务员)为空，则自动填写当前账户名字
            if (this.formData.registrar == null || this.formData.registrar == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
           }
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>


<style scoped>
    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }

    /deep/ .vxe-input--suffix {
        height: auto;
    }

    /deep/ .vxe-input.is--controls.type--number .vxe-input--suffix {
        right: 0.6em;
    }
    /deep/ .vxe-input--number-suffix {
        display: none;
    }
</style>
